import React from 'react'
import { Link } from 'gatsby'

import { Helmet } from 'react-helmet'

import projectStyles from '../style.module.css'
import styles from './contact1.module.css'

const Contact = (props) => {
  return (
    <div className={styles['container']}>
      <Helmet>
        <title>Contact - Covert_Bear</title>
        <meta property="og:title" content="Contact - Covert_Bear" />
      </Helmet>
      <div data-role="Header" className={styles['navbar-container']}>
        <div className={styles['navbar']}>
          <div className={styles['left-side']}>
            <Link to="/" className={styles['navlink']}>
              <img
                alt="image"
                src="/playground_assets/icon-white-200h.png"
                className={styles['image']}
              />
            </Link>
            <div data-type="BurgerMenu" className={styles['burger-menu']}>
              <svg viewBox="0 0 1024 1024" className={styles['icon']}>
                <path d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"></path>
              </svg>
            </div>
            <div className={styles['links-container']}>
              <Link
                to="/"
                className={` ${styles['link']} ${projectStyles['anchor']} `}
              >
                Home
              </Link>
              <Link
                to="/pricing"
                className={` ${styles['link01']} ${projectStyles['anchor']} `}
              >
                <span>Pricing</span>
              </Link>
              <Link
                to="/v-p-n"
                className={` ${styles['link02']} ${projectStyles['anchor']} `}
              >
                VPN
              </Link>
              <Link
                to="/windows-r-d-p"
                className={` ${styles['link03']} ${projectStyles['anchor']} `}
              >
                Windows RDP
              </Link>
              <Link
                to="/linux-r-d-p"
                className={` ${styles['link04']} ${projectStyles['anchor']} `}
              >
                Linux RDP
              </Link>
            </div>
          </div>
          <div className={styles['right-side']}></div>
          <div data-type="MobileMenu" className={styles['mobile-menu']}>
            <div className={styles['container1']}>
              <img
                alt="image"
                src="https://play.teleporthq.io/static/svg/default-img.svg"
                className={styles['image1']}
              />
              <div data-type="CloseMobileMenu" className={styles['close-menu']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon02']}>
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <div className={styles['links-container1']}>
              <a
                href="#resources"
                className={` ${styles['link05']} ${projectStyles['anchor']} `}
              >
                Resources
              </a>
              <span
                className={` ${styles['link06']} ${projectStyles['anchor']} `}
              >
                Inspiration
              </span>
              <a
                href="#process"
                className={` ${styles['link07']} ${projectStyles['anchor']} `}
              >
                Process
              </a>
              <span
                className={` ${styles['link08']} ${projectStyles['anchor']} `}
              >
                Our story
              </span>
            </div>
          </div>
        </div>
      </div>
      <div id="home" className={styles['hero']}>
        <div className={styles['content-container']}>
          <div className={styles['hero-text']}>
            <h1
              className={` ${styles['heading']} ${projectStyles['section-heading']} `}
            >
              Covert Bear
            </h1>
            <span
              className={` ${styles['text01']} ${projectStyles['section-text']} `}
            >
              <span>Virtual Private Network (VPN)</span>
              <br></br>
              <span>Remote Desktop Portal (RDP)</span>
            </span>
            <span
              className={` ${styles['text04']} ${projectStyles['section-text']} `}
            >
              <span>We&apos;ve got your back</span>
            </span>
          </div>
        </div>
      </div>
      <div id="contact" className={styles['get-in-touch']}>
        <h2
          className={` ${styles['text06']} ${projectStyles['section-heading']} `}
        >
          Get in touch
        </h2>
        <div className={styles['content-container1']}>
          <div className={styles['container2']}>
            <span className={styles['text07']}>
              <span>Send us an email in order to:</span>
              <br></br>
              <span>1. Place an order</span>
              <br></br>
              <span>2. Ask for more info</span>
              <br></br>
              <span>3. Give Feedback</span>
            </span>
            <span>
              <span>For Orders, please include the following information:</span>
              <br></br>
              <span>1. Service type</span>
              <br></br>
              <span> a. VPN</span>
              <br></br>
              <span> b. Windows RDP</span>
              <br></br>
              <span> c. Linux RDP</span>
              <br></br>
              <span>2. Location</span>
              <br></br>
              <span>3. Plan (see Pricing)</span>
              <br></br>
              <span> a. Monthly</span>
              <br></br>
              <span> b. 1-Year</span>
              <br></br>
              <span> c. 2-Year</span>
            </span>
          </div>
          <div className={styles['form-container']}>
            <span
              className={` ${styles['heading1']} ${projectStyles['big-card-heading']} `}
            >
              Send us a message
            </span>
            <input
              type="text"
              id="form_name"
              required="true"
              placeholder="Name"
              className={` ${styles['name']} ${projectStyles['input']} `}
            />
            <input
              type="text"
              id="form_email"
              required="true"
              placeholder="E-mail"
              className={` ${styles['email']} ${projectStyles['input']} `}
            />
            <textarea
              id="form_message"
              placeholder="Your Message"
              className={` ${styles['message']} ${projectStyles['textarea']} `}
            ></textarea>
            <button
              id="form_button"
              className={` ${styles['cta-btn']} ${projectStyles['button']} ${projectStyles['anchor']} `}
            >
              SEND
            </button>
          </div>
          <div className={styles['locations-container']}>
            <div className={styles['location-1']}>
              <span className={styles['heading2']}>Lewes, USA</span>
              <div className={styles['adress']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon04']}>
                  <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
                </svg>
                <span className={projectStyles['section-text']}>
                  Lewes, Delaware, 19958
                </span>
              </div>
              <div className={styles['email1']}>
                <svg viewBox="0 0 1024 1024" className={styles['icon06']}>
                  <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
                </svg>
                <span className={projectStyles['section-text']}>
                  contact@covertbear.com
                </span>
              </div>
              <div className={styles['phone']}>
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className={styles['icon08']}
                >
                  <path d="M562.857 556.571c9.714 0 102.857 48.571 106.857 55.429 1.143 2.857 1.143 6.286 1.143 8.571 0 14.286-4.571 30.286-9.714 43.429-13.143 32-66.286 52.571-98.857 52.571-27.429 0-84-24-108.571-35.429-81.714-37.143-132.571-100.571-181.714-173.143-21.714-32-41.143-71.429-40.571-110.857v-4.571c1.143-37.714 14.857-64.571 42.286-90.286 8.571-8 17.714-12.571 29.714-12.571 6.857 0 13.714 1.714 21.143 1.714 15.429 0 18.286 4.571 24 19.429 4 9.714 33.143 87.429 33.143 93.143 0 21.714-39.429 46.286-39.429 59.429 0 2.857 1.143 5.714 2.857 8.571 12.571 26.857 36.571 57.714 58.286 78.286 26.286 25.143 54.286 41.714 86.286 57.714 4 2.286 8 4 12.571 4 17.143 0 45.714-55.429 60.571-55.429zM446.857 859.429c197.714 0 358.857-161.143 358.857-358.857s-161.143-358.857-358.857-358.857-358.857 161.143-358.857 358.857c0 75.429 24 149.143 68.571 210.286l-45.143 133.143 138.286-44c58.286 38.286 127.429 59.429 197.143 59.429zM446.857 69.714c237.714 0 430.857 193.143 430.857 430.857s-193.143 430.857-430.857 430.857c-72.571 0-144.571-18.286-208.571-53.714l-238.286 76.571 77.714-231.429c-40.571-66.857-61.714-144-61.714-222.286 0-237.714 193.143-430.857 430.857-430.857z"></path>
                </svg>
                <span className={projectStyles['section-text']}>
                  +1 (646) 751-8570
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles['section-separator']}></div>
      <div className={styles['footer-container']}>
        <div className={styles['footer']}>
          <div className={styles['copyright-container']}>
            <span
              className={` ${styles['link09']} ${projectStyles['anchor']} `}
            >
              <span>© Copyright, 2021</span>
              <br></br>
              <span>Covert Bear, All Rights Reserved</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contact
